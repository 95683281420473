import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [

  
  {
    path: "/tableau-de-bord",
    title: "Tableau de bord",
    moduleName: "dashboard",
    iconType: "clock",
    icon: "plus",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    submenu: [],
  },
  {
    path: "",
    title: "Gestion hébergement",
    moduleName: "hebergement",
    iconType: "cog",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PARAM-NAV",
    submenu: [
      {
        path: "/hebergement/reservation-chambre",
        title: "Réservation-chambre",
        moduleName: "hebergement",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/hebergement/avance-reservation",
        title: "Avance réservation",
        moduleName: "hebergement",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/hebergement/hebergement",
        title: "Hébergement",
        moduleName: "hebergement",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/hebergement/avance-hebergement",
        title: "Avance hébergement",
        moduleName: "hebergement",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      
      
      
    ],
  },
  {
    path: "",
    title: "Location espace",
    moduleName: "location",
    iconType: "cog",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PARAM-NAV",
    submenu: [
      {
        path: "/location/commande",
        title: "Commande",
        moduleName: "location",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/location/facture-proforma",
        title: "Facture proforma",
        moduleName: "location",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/location/avance",
        title: "Avance",
        moduleName: "location",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/location/occupation-espace",
        title: "Occupation espace",
        moduleName: "location",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      
      
      
    ],
  },
  {
    path: "",
    title: "Restaurant",
    moduleName: "restaurant",
    iconType: "cog",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PARAM-NAV",
    submenu: [
      {
        path: "/restaurant/commande",
        title: "Commande",
        moduleName: "restaurant",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
     
      {
        path: "/restaurant/devis",
        title: "Devis",
        moduleName: "restaurant",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/restaurant/reglement-avance",
        title: "Règlement avance",
        moduleName: "restaurant",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      
      {
        path: "/restaurant/vente-directe",
        title: "Vente directe",
        moduleName: "restaurant",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/restaurant/livraison",
        title: "Livraison",
        moduleName: "restaurant",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      
      // {
      //   path: "/restaurant/reglement",
      //   title: "Règlements",
      //   moduleName: "restaurant",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "PARAM-ADMINISTRATIF",
      //   submenu: [],
      // },
      
      
    ],
  },
  {
    path: "",
    title: "Facturation",
    moduleName: "facturation",
    iconType: "cog",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PARAM-NAV",
    submenu: [
      {
        path: "/facturation/hebergement",
        title: "Facture hébergement",
        moduleName: "facturation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/facturation/location-espace",
        title: "Facture location espace",
        moduleName: "facturation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/facturation/commande-resto",
        title: "Facture commande resto",
        moduleName: "facturation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/facturation/remise-facture",
        title: "Remise facture",
        moduleName: "facturation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/facturation/reglement",
        title: "Règlement facture",
        moduleName: "facturation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      
    ],
  },
  {
    path: "",
    title: "Moyens généraux",
    moduleName: "moyens-generaux",
    iconType: "cog",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PARAM-NAV",
    submenu: [
      // {
      //   path: "/moyens-generaux/produit",
      //   title: "Produits",
      //   moduleName: "moyen-generaux",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "PARAM-ADMINISTRATIF",
      //   submenu: [],
      // },
      {
        path: "/moyens-generaux/commande-dmg",
        title: "Commande dmg",
        moduleName: "moyen-generaux",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/moyens-generaux/livraison-mg",
        title: "Livraison",
        moduleName: "moyen-generaux",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/moyens-generaux/rangement-produit",
        title: "Mise à jour stock",
        moduleName: "moyens-generaux",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      // {
      //   path: "/moyens-generaux/stockage",
      //   title: "Stockage",
      //   moduleName: "moyens-generaux",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "PARAM-ADMINISTRATIF",
      //   submenu: [],
      // },
      
    ],
  },

  {
    path: "",
    title: "Consommation",
    moduleName: "consommation",
    iconType: "cog",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PARAM-NAV",
    submenu: [
      {
        path: "/consommation/demande-chambre",
        title: "Demande chambre",
        moduleName: "consommation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/consommation/demande-espace",
        title: "Demande espace",
        moduleName: "consommation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/consommation/bon-sortie-chambre",
        title: "Bon sortie chambre",
        moduleName: "consommation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/consommation/bon-sortie-espace",
        title: "Bon sortie espace",
        moduleName: "consommation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },      
    ],
  },

  {
    path: "",
    title: "Paramètres",
    moduleName: "parametres",
    iconType: "cog",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "PARAM-NAV",
    submenu: [
      {
        path: "/parametres/hebergement",
        title: "Hébergement",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/parametres/location-espace",
        title: "Location espace",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/parametres/restaurant",
        title: "Restaurant",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/parametres/moyens-generaux",
        title: "Moyens généraux",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
      {
        path: "/parametres/autres",
        title: "Autres",
        moduleName: "parametres",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "PARAM-ADMINISTRATIF",
        submenu: [],
      },
    ],
  },
  {
    path: "/journal",
    title: "Journal",
    moduleName: "journal",
    iconType: "clock",
    icon: "plus",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    submenu: [],
  },
  {
    path: "/gestion-journal",
    title: "Validation-journal",
    moduleName: "gestion-journal",
    iconType: "clock",
    icon: "plus",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    submenu: [],
  },
  {
    path: "/rapport",
    title: "Rapports",
    moduleName: "rapport",
    iconType: "clock",
    icon: "plus",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    submenu: [],
  },

  // Administration acl ok
  {
    path: "",
    title: "Administration",
    moduleName: "administration",
    iconType: "lock",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "ADMIN-NAV",
    submenu: [
      {
        path: "/administration/utilisateurs",
        title: "Utilisateurs",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ADMIN-USERS",
        submenu: [],
      },
      {
        path: "/administration/roles",
        title: "Roles",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ADMIN-ROLE",
        submenu: [],
      },
      
      {
        path: "/administration/fonctionnalites",
        title: "Fonctionnalites",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        // code: "73166694",
        submenu: [],
      },
      {
        path: "/administration/historique-connexion",
        title: "Historique connexion",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "ADMIN-HISTORIQUE-CONNEXION",
        submenu: [],
      },
    ],
  },
];
