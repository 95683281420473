import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';

@Component({
  selector: 'app-rapport',
  templateUrl: './rapport.component.html',
  styleUrls: ['./rapport.component.scss']
})
export class RapportComponent implements OnInit {

  startDate=new Date();
  endDate=new Date();
  @ViewChild("templateCrud") templateCrud: any;
  currentDay=new Date();
  itemToSave:any={}
  isMouvement=false
  modalRef: BsModalRef;
    user: any = {};
    itemToSearch: any = {};
    currentUrl:any
    busyGet: Subscription
    data = [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]
    highchart: any
    items: any;
    bsRangeValue: Date[];
    minDate = new Date();
    maxDate = new Date();
    currentDate = new Date();
    totalSecteurs = 0
    totalControleurs = 0
    totalHospit: any;
    itemsSecteur: any;
    userImg:any
    colors = ['#236CB7', '#644033', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417'];
  itemDonneeJour: any={};
  dateValeur: Date;
  url: any;
  libelle: any;
  itemsMagasin: any=[];
  selectedProduit: any={};
  uniteFonctionnelle:any = {}

  constructor(private httpClient:HttpClient,private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = authService.currentUserValue
    this.uniteFonctionnelle = authService.getActiveUniteFonctionnelle()
    // this.maxDate.setDate(this.currentDate.getDate()+30);
    // this.minDate.setDate(this.currentDate.getDate()-30);
    let now = new Date();
    let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    this.startDate = firstDay
    this.endDate = lastDay

// // Get the first date of the current month
// const firstDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

// // Get the last day of the current month
// const lastDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    this.minDate = firstDay
    this.maxDate = lastDay
    this.dateValeur = new Date();
    // this.currentDay=new Date()





    
}

download() {
  
  let request = {
    user:this.user.id,    
    isSimpleLoading:false,
    data:{        
      createdAtParam:{
        operator: "[]",            
        start:this.startDate?moment(this.startDate).format("DD/MM/YYYY") :moment().format("DD/MM/YYYY") ,
        end:this.endDate?moment(this.endDate).format("DD/MM/YYYY") :moment().format("DD/MM/YYYY"),
        medicamentId:this.selectedProduit.id,
        magasinId:this.itemToSave.magasinId      
      }
    }  }
  console.log('res: ', request);

  this.busyGet = this.restClient.post(this.url, request).subscribe({
      next: (res: any) => {
      this.busyGet.unsubscribe()

          console.log('res here', res);

          if (res && !res.hasError && res.filePath) {
              window.open(res.filePath)
              // this.modalRef.hide()

          }
          else {
            this.utilities.showNotification("snackbar-danger",
              // this.utilities.formatMsgServeur(res['status']['message']),
            res['status']['message'],
              "bottom",
              "center");
          }
          // this.drawBarChart(this.items)


      },
      error: (err:any) => {
          this.utilities.showNotification("snackbar-danger",
              err,
              "top",
              "right");
      },
      complete: () => console.info('complete')
  })
}

// exportRecetteByUf() {
//   let url = 'tresoJournalPrincipal/exportJournal';
//   let request = {
//     user:this.user.id,    
//     isSimpleLoading:false,
//     data:{ 
//       id:this.uniteFonctionnelle.id    
      
//     }  
//   }
//   console.log('res: ', request);

//   this.busyGet = this.restClient.post(url, request).subscribe({
//       next: (res: any) => {
//       this.busyGet.unsubscribe()

//           console.log('res here', res);

//           if (res && !res.hasError && res.filePath) {
//               window.open(res.filePath)
//           }
//           else {
//             this.utilities.showNotification("snackbar-danger",
//               // this.utilities.formatMsgServeur(res['status']['message']),
//             res['status']['message'],
//               "bottom",
//               "center");
//           }
//           // this.drawBarChart(this.items)


//       },
//       error: (err:any) => {
//           this.utilities.showNotification("snackbar-danger",
//               err,
//               "top",
//               "right");
//       },
//       complete: () => console.info('complete')
//   })
// }

openModal(url,libelle:any) {

  

  // this.isMouvement = false,
  let config = {backdrop: true, ignoreBackdropClick: true,id: 1, class: 'modal-md'};
  this.url = url
  this.libelle = libelle
  // this.isMouvement = libelle == 'Mouvements'
  this.modalRef = this.modalService.show(this.templateCrud,config);
}

medicamentSearchSelected(elt:any){
  console.info('elt: ',elt)
  this.selectedProduit = elt
}

getItemsMagasin() {
  let request = {
    user: this.user.id,
    data: {

    },
  }

  this.busyGet = this.restClient.post('pharmacieMagasin/getByCriteria', request)
    .subscribe(
      res => {
        console.log('pharmacieMagasin',res);

        if (res && res['items']) {
          this.itemsMagasin = res['items'];

        }
        else {
          this.itemsMagasin = [];
        }
      },
      err => {
      }
    );
}
  
  ngOnInit(): void {
    // this.getItemsMagasin()
  }

}
