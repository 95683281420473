<section class="content" >
    <div class="content-block">

        <div class="container-fluid" [ngBusy]="{
          busy: busyGet,
          message: 'Veuillez patienter ...',
          backdrop: false
        }">
            
            <br>
            <div class="row excl-elt">
              <div class="col col-4 col-sm-3 col-md-4 col-xl-1" style="text-align: center;" (click)="openModal('reservationCh/exportReservation','Réservations')">
                <i class="fa fa-file-excel fa-5x" aria-hidden="true"></i>
                <br>
                <h4 style="font-weight: 400;">Réservations</h4>
              </div>
              <div class="col col-4 col-sm-3 col-md-4 col-xl-1" style="text-align: center;" (click)="openModal('hebergement/exportHebergement','Hébergements')">
                <i [class.isActive]="" class="fa fa-file-excel fa-5x" aria-hidden="true"></i><br>
                <h4 style="font-weight: 400;">Hébergements</h4>
              </div>
            </div>
          </div>   
      </div>
  </section>


  <ng-template #templateCrud style="z-index: 1055;">
    <div class="modal-header">
        <h4 class="modal-title pull-left">
           Export état {{libelle}}
        </h4>
        <button mat-icon-button class="pull-right" (click)="modalRef.hide()"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="modal-body"
        >
        <form class="m-1" >
          <div class="row">
            <div class="col col-md-6">
            <label for="">Date debut</label>
            <input class="form-control" type="date" name="startDate" [ngModel] ="startDate | date:'yyyy-MM-dd'" (ngModelChange)="startDate = $event">

            </div>
            <div  class="col col-md-6">
            <label for="">Date fin</label>

            <input class="form-control" type="date" name="endDate" [ngModel] ="endDate | date:'yyyy-MM-dd'" (ngModelChange)="endDate = $event">

            </div>
            <div class="col col-md-6" *ngIf="isMouvement">
              <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1"
                >
                  <label
                    >Magasin :
                    <span style="font-weight: 700;" class="required_champs">*</span>
                  </label>
                  <ng-select
                    [(ngModel)]="itemToSave.magasinId"
                    placeholder="Choisissez "
                    name="magasinId"
                    id="magasinId"
                    [multiple]="false"
                    [closeOnSelect]="true"
                  >
                    <ng-option
                      *ngFor="let item of itemsMagasin"
                      [value]="item.id"
                      >{{ item?.libelle }}
                    </ng-option>
                  </ng-select>
                </div>
            </div>
            <div class="col col-md-6" *ngIf="isMouvement">
              <label for="">Médicament
                <span style="font-weight: 700;" class="required_champs">*</span>
              </label>

                                    
              <app-search-medicament-in-magasin [isDisabled]="!itemToSave.magasinId" [idMagasin]="itemToSave.magasinId" (itemMedicamentSelected)="medicamentSearchSelected($event)"></app-search-medicament-in-magasin>
            </div>
            

        </div>
        </form>

    </div>
    <div class="modal-footer modal-footer-custom">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

            <button type="button" (click)="download()"
                class="btn btn-md custom_btn_primary pull-right"
                 [class.auth-spinner]="loading"
                [disabled]="loading"> Exporter</button>
            <button type="button" class="btn btn-md btn-cancel pull-right"
                (click)="modalRef.hide()">Annuler</button>
        </div>
    </div>
</ng-template>

  