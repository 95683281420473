import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/service/auth.service";
import { RestClientService } from "src/app/core/service/rest-client.service";
import { UtilitiesService } from "src/app/core/service/utilities.service";
import Swal from "sweetalert2";
import * as _ from "lodash";
import { PreviewPdfModalComponent } from "src/app/shared/components/preview-pdf-modal/preview-pdf-modal.component";

@Component({
  selector: 'app-histo-gestion-journal',
  templateUrl: './histo-gestion-journal.component.html',
  styleUrls: ['./histo-gestion-journal.component.scss']
})
export class HistoGestionJournalComponent implements OnInit {

  listItems: Array<any> = [];
  ListeDirections: Array<any> = [];
  items: Array<any> = [];
  itemToSave: any = {};
  modalRef: BsModalRef;
  itemsChambre: any = [];
  itemsChambreSelected: any = [];
  itemToAdd: any = {};
  user: any = {};
  itemToSearch: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  busyGet: Subscription;
  busySave: Subscription;
  loading: boolean = false;
  endPoint: string = "tresoJournal/";
  itemsModeReglement: any = [];
  itemsMotifReglement: any = [];
  datasConso: any;
  clientOccupant: any;
  bsModalRefPreview:BsModalRef
  constructor(
    private authService: AuthService,
    private restClient: RestClientService,
    private modalService: BsModalService,
    private utilities: UtilitiesService
  ) {
    this.user = this.authService.currentUserValue;
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getItems();
  }

  confirmSaveItem(item) {
    let objToSave = Object.assign({}, item);

    if (!item || !item.modeReglementId) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner mode règlement !",
        "bottom",
        "center"
      );
      return;
    }

    if (!item || !item.montant) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner Montant règlé !",
        "bottom",
        "center"
      );
      return;
    }
    // if(item.)
    if (!item || !item.montantRemis) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner Montant remis !",
        "bottom",
        "center"
      );
      return;
    }

   
    if (!item || !item.motifReglementId) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner motif !",
        "bottom",
        "center"
      );
      return;
    }

    if (Number(item.montantRemis)<Number(item.montant)) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Le montant remis doit etre supérieur ou égal au montant règlé !",
        "bottom",
        "center"
      );
      return;
    }
    Swal.fire({
      title: "Règlement hébergement",
      text: "Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        // objToSave.libelle = objToSave.libelle.toUpperCase();
        this.saveItem(objToSave);
      }
    });
  }

  openModal(data: any, template: TemplateRef<any>) {
    let config = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-width-75",
    };
    console.log("data: ", data);
    if (data) {
      // Lorsque nous sommes en modification
      this.itemToSave = Object.assign({}, data);
      this.clientOccupant =
        this.itemToSave.clientOccupantNom +
        " " +
        this.itemToSave.clientOccupantPrenom;

      this.datasConso = this.itemToSave.datasConso;

      if (this.datasConso && this.datasConso.length) {
        this.datasConso.map((dc) => {
          dc.quantite = dc.qte;
          dc.prix = dc.priceu;
          dc.total = dc.prix * dc.quantite;
        });
      }
    }
    let firstId = this.itemsMotifReglement.filter((rit) => rit.code == "AV")[0]
      .id;
    console.log("firstId: ", firstId);
    this.itemToSave.motifReglementId = firstId;
    this.itemToSave.modeReglementId = this.itemsModeReglement.filter(imr=>imr.code =='ESP')[0].id

    this.modalRef = this.modalService.show(template, config);
  }
  saveItem(item) {
    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      datas: [
        {
          motifReglementId: itemAEnregistrer.motifReglementId,
          modeReglementId: itemAEnregistrer.modeReglementId,
          montant: itemAEnregistrer.montant,
          hebergementId: itemAEnregistrer.id,
          monnaie: itemAEnregistrer.monnaie,
        },
      ],
    };

    this.busySave = this.restClient
      .post(
        "reglement/" +
          (itemAEnregistrer.id
            ? "createReglementHebergement"
            : "createReglementHebergement"),
        request
      )
      .subscribe(
        (res) => {
          console.log("resul", res);
          this.loading = false;

          if (!res["hasError"]) {
            if (res["items"] && res["items"].length > 0) {
              this.utilities.showNotification(
                "snackbar-success",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );

              this.getItems();
              this.modalRef.hide();
            }
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
          this.loading = false;
        }
      );
  }

  getItems() {
    let request = {
      user: this.user.id,
      data: {
        // libelle: this.itemToSearch.libelle ? this.itemToSearch.libelle : null
        isValidated:true
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage,
    };

    this.busyGet = this.restClient
      .post(this.endPoint + "/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.items = res["items"];
            this.totalItems = res["count"];
          } else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        (err) => {}
      );
  }

  confirmDelete(item) {
    Swal.fire({
      title: "Action",
      text: "Vous êtes sur le point de supprimer un élément.  Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3f51b5",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.deleteItem(item);
      }
    });
  }

  deleteItem(obj) {
    var request = {
      user: this.user.id,
      datas: [{ id: obj.id }],
    };

    this.busyGet = this.restClient
      .post(this.endPoint + "delete", request)
      .subscribe(
        (res) => {
          console.log(res);
          if (!res["hasError"]) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );

            this.currentPage = 1;
            this.getItems();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = (page - 1) * 10 + (index + 1);
    }
    return num;
  }

  getDr() {
    let request = {
      user: this.user.id,
      data: {},
    };

    this.busyGet = this.restClient
      .post("adminDirectionRegionale/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.ListeDirections = res["items"];
          } else {
            this.ListeDirections = [];
          }
        },
        (err) => {}
      );
  }

  getItemsModeReglement() {
    let request = {
      user: this.user.id,
      data: {},
    };

    this.busyGet = this.restClient
      .post("modeReglement/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.itemsModeReglement = res["items"];
          } else {
            this.itemsModeReglement = [];
          }
        },
        (err) => {}
      );
  }

  getItemsMotifReglement() {
    let request = {
      user: this.user.id,
      data: {
        // code: "AV"
      },
    };

    this.busyGet = this.restClient
      .post("motifReglement/getByCriteria", request)
      .subscribe(
        (res: any) => {
          if (res && res["items"]) {
            this.itemsMotifReglement = res["items"];
          } else {
            this.itemsMotifReglement = [];
          }
        },
        (err) => {}
      );
  }

  changePaginationSize($event) {
    if ($event) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt($event);
    }
    this.getItems();
  }

  checkAmount() {
    if (this.itemToSave.montant > this.itemToSave.leftToPaid)
      this.itemToSave.montant = this.itemToSave.leftToPaid;

    if(this.itemToSave.montantRemis && (Number(this.itemToSave.montantRemis) > Number(this.itemToSave.montant))){
      this.itemToSave.monnaie = this.itemToSave.montantRemis - this.itemToSave.montant
    }
    else{
    this.itemToSave.monnaie = 0
      
    }
  }

  onToggle(it) {
    let tempon = it.isDetailsOpen;
    this.items.forEach((element) => {
      element.isDetailsOpen = false;
    });
    it.isDetailsOpen = !tempon;
    // if (it.isDetailsOpen) {
    //   this.itemsMedicament = it.medicaments;
    // }
  }

  onPrintItem(item) {
    // this.openPreviewModal('https://pdfobject.com/pdf/sample.pdf')
    let request = {
      user: this.user.id,
      data: {
        id:item.id
      },
    };

    this.busyGet = this.restClient
      .post("tresoJournal/generateJournal", request)
      .subscribe(
        (res) => {
          if (res && res['filePath']) {
            this.openPreviewModal(res['filePath'])
          } else {
            
          }
        },
        (err) => {
        }
      );
  }
  openPreviewModal(url) {
    this.bsModalRefPreview = this.modalService.show(PreviewPdfModalComponent, {
      animated: true,
      keyboard: false,
      backdrop: "static",
      ignoreBackdropClick: true,
      class: "gray modal-lg modal-width-65 modal-custom",
    });
    this.bsModalRefPreview.content.currentLink = url;
  }

  confirmValidate(item) {
    let objToSave = Object.assign({}, item);
    Swal.fire({
      title: "Valider journal",
      text: "Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.validate(objToSave);
      }
    });
  }

  validate(obj) {
    var request = {
      user: this.user.id,
      datas: [{ id: obj.id,isValidated:true }],
    };

    this.busyGet = this.restClient
      .post(this.endPoint + "update", request)
      .subscribe(
        (res) => {
          console.log(res);
          if (!res["hasError"]) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );

            this.currentPage = 1;
            this.getItems();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }


  getTotal() {
    let total = 0;
    if(this.datasConso && this.datasConso.length)
      this.datasConso.map((ics) => (total += Number(ics.total) || 0));
    return total;
  }

  ngOnInit() {
    this.getItems();
    // this.getItemsModeReglement();
    // this.getItemsMotifReglement();
  }


}
