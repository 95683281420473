import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LockScreenModalComponent } from './lock-screen-modal/lock-screen-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLockScreenService {

  // private timeout: number = 600000; // 10 minutes in milliseconds
  private timeout: number = 600000

  private timer: any;
  modalRef?: BsModalRef;
  user:any
  constructor(private router: Router,private modalService: BsModalService,private authService:AuthService) {
    this.user = authService.currentUserValue
    this.user = this.authService.currentUserValue


  }

  resetTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // this.router.navigate(['/logout']); // Redirect to logout route or implement your logout logic
      this.showLockScreenModal()
    }, this.timeout);
  }

  startTimer() {
    console.log('start timer')
    // let isLocked = !!localStorage.getItem('isLocked')
    // if(!isLocked){
    //   this.showLockScreenModal()
    // }
    // else{
    // localStorage.setItem('isLocked','false')

    //   this.modalRef.hide()
    // }
    document.addEventListener('mousemove', () => this.resetTimer());
    document.addEventListener('keypress', () => this.resetTimer());
    this.resetTimer();
  }

  isModalOpen(): boolean {
    return this.modalService.getModalsCount() > 0;
  }
  
  showLockScreenModal(){
 
    // if(isLocked == 'true'){
    //   return
    // }
    localStorage.setItem('isLocked',this.isModalOpen()?'true':'false')
    let isLocked = localStorage.getItem('isLocked')
    console.log('isLocked: ', isLocked)
    console.log('is open mod: ', this.isModalOpen())
    if(!this.authService.isUserAuthed()){
      this.closeModal()
      return
    }

    


    // if(isLocked == 'true' && !this.isModalOpen()){
    // if(isLocked == 'true' && !this.isModalOpen()){
      if(!this.isModalOpen()){
      let modal:any
      modal = LockScreenModalComponent
      this.modalRef = this.modalService.show(modal, {
        id: 1,
        class: 'modal-lock-screen',
        // data:boulangerie
      });

      // if(!this.authService.isUserAuthed() && this.isModalOpen()){
      //   this.modalRef.hide()
      //   return
      // }

      localStorage.setItem('isLocked',this.isModalOpen()?'true':'false')
      console.log('is open app: ', this.isModalOpen())
      // if (data) this.modalRef.content.currentData = data;
      this.modalRef.onHide.subscribe((res) => {
        console.log('closed')
      localStorage.setItem('isLocked',this.isModalOpen()?'true':'false')
        // this.getData();
      });
    }
    
  }

  closeModal(){
    if(this.isModalOpen()){
      this.modalService.hide()
      return
    }
  }
}
