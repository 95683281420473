import { RapportComponent } from './rapport/rapport.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { Role } from "./core/models/role";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { TableauDeBordComponent } from "./tableau-de-bord/tableau-de-bord.component";
import { JournalComponent } from './journal/journal.component';
import { MainJournalComponent } from './journal/main-journal/main-journal.component';
import { GestionJournalComponent } from './gestion-journal/gestion-journal.component';
import { MainGestionJournalComponent } from './gestion-journal/main-gestion-journal/main-gestion-journal.component';
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      // { path: "tableau-de-bord", component:TableauDeBordComponent },
      { path: "tableau-de-bord", canActivate: [AuthGuard],
      component:TableauDeBordComponent
       },
      {
        path: "admin",
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "accueil",
        canActivate: [AuthGuard],
        component:HomePageComponent
      },
      
      {
        path: "parametres",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./parametres/parametres.module").then(
            (m) => m.ParametresModule
          ),
      },
      {
        path: "hebergement",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./hebergement/hebergement.module").then(
            (m) => m.HebergementModule
          ),
      },
      {
        path: "restaurant",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./restaurant/restaurant.module").then(
            (m) => m.RestaurantModule
          ),
      },
      {
        path: "facturation",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./facturation/facturation.module").then(
            (m) => m.FacturationModule
          ),
      },
      {
        path: "moyens-generaux",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./moyens-generaux/moyens-generaux.module").then(
            (m) => m.MoyensGenerauxModule
          ),
      },
      {
        path: "consommation",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./conso/conso.module").then(
            (m) => m.ConsoModule
          ),
      },
      {
        path: "restaurant",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./restaurant/restaurant.module").then(
            (m) => m.RestaurantModule
          ),
      },
      {
        path: "location",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./location-espace/location-espace.module").then(
            (m) => m.LocationEspaceModule
          ),
      },
      {
        path: "administration",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./administration/administration.module").then(
            (m) => m.AdministrationModule
          ),
      },
      {
        path: "rapport",
        component:RapportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "journal",
        component:MainJournalComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "gestion-journal",
        component:MainGestionJournalComponent,
        canActivate: [AuthGuard],
      },
      
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
