import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/service/auth.service";
import { RestClientService } from "src/app/core/service/rest-client.service";
import { UtilitiesService } from "src/app/core/service/utilities.service";
import Swal from "sweetalert2";
import * as _ from "lodash";
import { PreviewPdfModalComponent } from "../shared/components/preview-pdf-modal/preview-pdf-modal.component";
import * as moment from "moment";

@Component({
  selector: 'app-gestion-journal',
  templateUrl: './gestion-journal.component.html',
  styleUrls: ['./gestion-journal.component.scss']
})
export class GestionJournalComponent implements OnInit {

  listItems: Array<any> = [];
  ListeDirections: Array<any> = [];
  items: Array<any> = [];
  itemToSave: any = {};
  modalRef: BsModalRef;
  itemsChambre: any = [];
  itemsChambreSelected: any = [];
  itemToAdd: any = {};
  user: any = {};
  itemToSearch: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  busyGet: Subscription;
  busySave: Subscription;
  loading: boolean = false;
  endPoint: string = "tresoJournal/";
  itemsModeReglement: any = [];
  itemsMotifReglement: any = [];
  datasConso: any;
  clientOccupant: any;
  bsModalRefPreview:BsModalRef
  itemsReglement: any=[];
  itemsReglementChecked: any=[];
  constructor(
    private authService: AuthService,
    private restClient: RestClientService,
    private modalService: BsModalService,
    private utilities: UtilitiesService
  ) {
    this.user = this.authService.currentUserValue;
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getItems();
  }

  confirmSaveItem(item) {
    let objToSave = Object.assign({}, item);

    if (!item || !item.dateJournal) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner date journal !",
        "bottom",
        "center"
      );
      return;
    }
    if (!item || !item.dateFinJournal) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner date fin journal !",
        "bottom",
        "center"
      );
      return;
    }

    if (!item || !item.amountJournal) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Veuillez renseigner cocher au moin un règlement !",
        "bottom",
        "center"
      );
      return;
    }
    if (moment(item.dateFinJournal).isBefore(item.dateJournal)) {
      this.utilities.showNotification(
        "snackbar-danger",
        "Date journal doit etre inferieure à date fin journal !",
        "bottom",
        "center"
      );
      return;
    }
    objToSave.dateFinJournal = moment(objToSave.dateFinJournal).format('DD/MM/YYYY')
    objToSave.dateJournal = moment(objToSave.dateJournal).format('DD/MM/YYYY')

    this.itemsReglementChecked = this.itemsReglement.filter(
      (iae) => iae.isChecked
    );
    Swal.fire({
      title: "Journal",
      text: "Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        // objToSave.libelle = objToSave.libelle.toUpperCase();
        this.saveItem(objToSave);
      }
    });
  }

  openModal(data: any, template: TemplateRef<any>) {
    this.getItemsReglement()
    // this.itemsReglement = []
    // this.itemsReglementChecked = []

    let config = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-width-75",
    };
    console.log("data: ", data);
    if (data) {
      // Lorsque nous sommes en modification
      this.itemToSave = Object.assign({}, data);
      this.clientOccupant =
        this.itemToSave.clientOccupantNom +
        " " +
        this.itemToSave.clientOccupantPrenom;

      this.datasConso = this.itemToSave.datasConso;

      if (this.datasConso && this.datasConso.length) {
        this.datasConso.map((dc) => {
          dc.quantite = dc.qte;
          dc.prix = dc.priceu;
          dc.total = dc.prix * dc.quantite;
        });
      }
    }
    // let firstId = this.itemsMotifReglement.filter((rit) => rit.code == "AV")[0]
    //   .id;
    // console.log("firstId: ", firstId);
    // this.itemToSave.motifReglementId = firstId;
    // this.itemToSave.modeReglementId = this.itemsModeReglement.filter(imr=>imr.code =='ESP')[0].id

    this.modalRef = this.modalService.show(template, config);
  }
  saveItem(item) {
    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);
    itemAEnregistrer.datasReglement = this.itemsReglement

    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer
      ],
    };

    this.busySave = this.restClient
      .post(
        "tresoJournal/" +
          (itemAEnregistrer.id
            ? "create"
            : "create"),
        request
      )
      .subscribe(
        (res) => {
          console.log("resul", res);
          this.loading = false;

          if (!res["hasError"]) {
            if (res["items"] && res["items"].length > 0) {
              this.utilities.showNotification(
                "snackbar-success",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );

              this.getItems();
              this.modalRef.hide();
            }
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
          this.loading = false;
        }
      );
  }

  getItems() {
    let request = {
      user: this.user.id,
      data: {
        // libelle: this.itemToSearch.libelle ? this.itemToSearch.libelle : null
        isValidated:false
      },
      index: this.currentPage - 1,
      size: this.itemsPerPage,
    };

    this.busyGet = this.restClient
      .post(this.endPoint + "/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.items = res["items"];
            this.totalItems = res["count"];
          } else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        (err) => {}
      );
  }
  getItemsReglement() {
    let request = {
      user: this.user.id,
      data: {
        // libelle: this.itemToSearch.libelle ? this.itemToSearch.libelle : null
        isJournal:false,
        dateJournal:moment(this.itemToSave.dateJournal),
        dateFinJournal:moment(this.itemToSave.dateFinJournal)
      },
      // index: this.currentPage - 1,
      // size: this.itemsPerPage,
    };

    this.busyGet = this.restClient
      .post("reglement/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.itemsReglement = res["items"];
            this.itemsReglementChecked = [...this.itemsReglement]
            this.getTotalAmount()
          } else {
            this.itemsReglement = [];
          }
        },
        (err) => {}
      );
  }

  confirmDelete(item) {
    Swal.fire({
      title: "Action",
      text: "Vous êtes sur le point de supprimer un élément.  Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3f51b5",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.deleteItem(item);
      }
    });
  }

  deleteItem(obj) {
    var request = {
      user: this.user.id,
      datas: [{ id: obj.id }],
    };

    this.busyGet = this.restClient
      .post(this.endPoint + "delete", request)
      .subscribe(
        (res) => {
          console.log(res);
          if (!res["hasError"]) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );

            this.currentPage = 1;
            this.getItems();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = (page - 1) * 10 + (index + 1);
    }
    return num;
  }

  getDr() {
    let request = {
      user: this.user.id,
      data: {},
    };

    this.busyGet = this.restClient
      .post("adminDirectionRegionale/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.ListeDirections = res["items"];
          } else {
            this.ListeDirections = [];
          }
        },
        (err) => {}
      );
  }

  getItemsModeReglement() {
    let request = {
      user: this.user.id,
      data: {},
    };

    this.busyGet = this.restClient
      .post("modeReglement/getByCriteria", request)
      .subscribe(
        (res) => {
          if (res && res["items"]) {
            this.itemsModeReglement = res["items"];
          } else {
            this.itemsModeReglement = [];
          }
        },
        (err) => {}
      );
  }

  getItemsMotifReglement() {
    let request = {
      user: this.user.id,
      data: {
        // code: "AV"
      },
    };

    this.busyGet = this.restClient
      .post("motifReglement/getByCriteria", request)
      .subscribe(
        (res: any) => {
          if (res && res["items"]) {
            this.itemsMotifReglement = res["items"];
          } else {
            this.itemsMotifReglement = [];
          }
        },
        (err) => {}
      );
  }

  changePaginationSize($event) {
    if ($event) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt($event);
    }
    this.getItems();
  }

  checkAmount() {
    if (this.itemToSave.montant > this.itemToSave.leftToPaid)
      this.itemToSave.montant = this.itemToSave.leftToPaid;

    if(this.itemToSave.montantRemis && (Number(this.itemToSave.montantRemis) > Number(this.itemToSave.montant))){
      this.itemToSave.monnaie = this.itemToSave.montantRemis - this.itemToSave.montant
    }
    else{
    this.itemToSave.monnaie = 0
      
    }
  }

  onToggle(it) {
    let tempon = it.isDetailsOpen;
    this.items.forEach((element) => {
      element.isDetailsOpen = false;
    });
    it.isDetailsOpen = !tempon;
    // if (it.isDetailsOpen) {
    //   this.itemsMedicament = it.medicaments;
    // }
  }

  onPrintItem(item) {
    // this.openPreviewModal('https://pdfobject.com/pdf/sample.pdf')
    let request = {
      user: this.user.id,
      data: {
        id:item.id
      },
    };

    this.busyGet = this.restClient
      .post("tresoJournal/generateJournal", request)
      .subscribe(
        (res) => {
          if (res && res['filePath']) {
            this.openPreviewModal(res['filePath'])
          } else {
            
          }
        },
        (err) => {
        }
      );
  }
  openPreviewModal(url) {
    this.bsModalRefPreview = this.modalService.show(PreviewPdfModalComponent, {
      animated: true,
      keyboard: false,
      backdrop: "static",
      ignoreBackdropClick: true,
      class: "gray modal-lg modal-width-65 modal-custom",
    });
    this.bsModalRefPreview.content.currentLink = url;
  }

  confirmValidate(item) {
    let objToSave = Object.assign({}, item);
    Swal.fire({
      title: "Valider journal",
      text: "Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.validate(objToSave);
      }
    });
  }

  validate(obj) {
    var request = {
      user: this.user.id,
      datas: [{ id: obj.id,isValidated:true }],
    };

    this.busyGet = this.restClient
      .post(this.endPoint + "update", request)
      .subscribe(
        (res) => {
          console.log(res);
          if (!res["hasError"]) {
            this.utilities.showNotification(
              "snackbar-success",
              this.utilities.formatMsgServeur(res["status"]["message"]),
              "bottom",
              "center"
            );

            this.currentPage = 1;
            this.getItems();
          } else {
            if (res["status"] && res["status"]["message"]) {
              this.utilities.showNotification(
                "snackbar-danger",
                this.utilities.formatMsgServeur(res["status"]["message"]),
                "bottom",
                "center"
              );
            }
          }
        },
        (err) => {
          console.log("Error occured", err);
          this.utilities.showNotification(
            "snackbar-danger",
            this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center"
          );
        }
      );
  }

  getTotalAmount() {
    let total = 0;
    console.log("this.itemsReglement: ", this.itemsReglement);
    this.itemsReglement.map((ih) => {
      // if (ih.isChecked) {
        console.log("ih.leftToPaid: ", ih.montant);
        total += Number(ih.montant);
      // }
    });
    this.itemToSave.amountJournal = total;
  }

  checkUncheckAll(event) {
    const target = event.target as HTMLInputElement;
    this.itemsReglement.map((ija) => {
      ija.isChecked = target.checked;
    });
    this.getTotalAmount();
  }


  getTotal() {
    let total = 0;
    if(this.datasConso && this.datasConso.length)
      this.datasConso.map((ics) => (total += Number(ics.total) || 0));
    return total;
  }

  ngOnInit() {
    this.getItems();
    this.getItemsReglement()
    // this.getItemsModeReglement();
    // this.getItemsMotifReglement();
  }


}
