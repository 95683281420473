import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import { NgxMaskModule } from "ngx-mask";
import { NgBusyModule } from "ng-busy";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { MoneyFormatPipe } from "../core/pipes/money-format.pipe";

// import { PaginationModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AddDocumentComponent } from "./components/add-document/add-document.component";
import { CKEditorModule } from "ng2-ckeditor";
import { MatTabsModule } from "@angular/material/tabs";
import { FindMedocComponent } from "./components/find-medoc/find-medoc.component";
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgSelectModule } from "@ng-select/ng-select";
import { FormPreviewPdfComponent } from "./components/form-preview-pdf/form-preview-pdf.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { ElementByPagePaginatorComponent } from "./components/element-by-page-paginator/element-by-page-paginator.component";
import { TimePickerComponent } from "./components/time-picker/time-picker.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TimeLineComponent } from "./components/time-line/time-line.component";
import { AppAccessDirective } from "./directive/app-access.directive";
// import { CKEditorModule } from 'ng2-ckeditor';
import { ChartModule } from 'angular-highcharts';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ChartsModule as chartjsModule } from "ng2-charts";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgApexchartsModule } from "ng-apexcharts";
import { HighchartsChartModule } from 'highcharts-angular';
import { LockScreenModalComponent } from "../core/service/lock-screen-modal/lock-screen-modal.component";
import { FullCalendarModule } from '@fullcalendar/angular'; // Import FullCalendarModule
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import interactionPlugin from '@fullcalendar/interaction';
import { SafePipe } from "../core/pipes/safe.pipe";
@NgModule({
  declarations: [
    MoneyFormatPipe,
    AddDocumentComponent,
    FindMedocComponent,
    FormPreviewPdfComponent,
    ElementByPagePaginatorComponent,
    TimePickerComponent,
    TimeLineComponent,
    AppAccessDirective,
    LockScreenModalComponent,
    SafePipe
    // ProgrammeDialyseComponent,
    // FormCreateProgrammeComponent,
    // ProgrammeDialyseAgendaComponent,
    // HistoriqueProgrammeDialyseComponent,
    // DetailsPatientsProgrammeComponent
    
  ],
  imports: [
    CommonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    NgBusyModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatTabsModule,
    NgSelectModule,
    FullCalendarModule, 
    MatIconModule,
    MatButtonModule,
    CKEditorModule,
    ChartModule,
    PopoverModule.forRoot(),
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'red',
      switchColor: 'white',
      defaultBgColor: 'green',
      defaultBoColor : '#476EFF',
      checkedLabel: 'Inactif',
      uncheckedLabel: 'Actif',
      checkedTextColor:'white',
      uncheckedTextColor:'white'
    }),
    chartjsModule,
    NgApexchartsModule,
    PerfectScrollbarModule, 
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    HighchartsChartModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    
    
  ],
  exports: [
    CommonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    NgBusyModule,
    PaginationModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    PopoverModule,
    // PaginationModule.forRoot(),
    // NgxMaskModule,
    MoneyFormatPipe,
    BsDatepickerModule,
    AddDocumentComponent,
    FindMedocComponent,
    FormPreviewPdfComponent,
    UiSwitchModule,
    NgSelectModule,
    FullCalendarModule,

    NgbModule,
    ElementByPagePaginatorComponent,
    TimePickerComponent ,
    TimeLineComponent,
    AppAccessDirective,
    ChartModule,
    chartjsModule,
    NgApexchartsModule,
    PerfectScrollbarModule, 
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    NgApexchartsModule,
    PerfectScrollbarModule, 
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    HighchartsChartModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    SafePipe

  ],
  entryComponents:[TimePickerComponent]
})
export class SharedModule {}