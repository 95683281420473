<section class="content">
    <div class="content-block">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <!-- <div class="header mb-3">
                        <h2><strong>Gestion hébergement ></strong> avance hébergement</h2>
                    </div> -->
                    <div class="body">
                        <div class="row">

                            <mat-tab-group mat-align-tabs="start" #tabGroup
                                (selectedIndexChange)="selectedChangeValue($event)" [(selectedIndex)]="indiceTab">
                                <mat-tab label="+Nouveau journal">
                                    <div *ngIf="indiceTab == 0" style="overflow-x: hidden">
                                        <app-gestion-journal></app-gestion-journal>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Historique journal">
                                    <div *ngIf="indiceTab == 1" style="overflow-x: hidden">
                                        <app-histo-gestion-journal></app-histo-gestion-journal>
                                    </div>
                                </mat-tab>
                              
                            </mat-tab-group>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>