<div class="auth-container">
  <div class="row auth-main">
    <div class="col-sm-6 px-0 d-none d-sm-block">
      <div
        class="left-img"
        style="
          background-image: url(assets/images/404.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        "
      ></div>
    </div>
    <div class="col-sm-6 auth-form-section">
      <div class="form-section">
        <div class="auth-wrapper">
          <div class="logo-part">
            <!-- <img src="assets/images/images-app/logo_madgi.jpg" alt="Logo MADGI" class="logo_client" /> -->

            <div class="logo-app-part">
              <!-- <img src="assets/images/images-app/logo-app.png" class="logo_app" /> -->
              <img
                src="assets/images/custom/logosavane.jpg"
                class="logo-app"
                alt=""
              />
            </div>
          </div>

          <div class="text-login">
            <h2 class="login-title">404 Page non trouvée</h2>
            <a style="text-decoration: underline; color:blue;cursor:pointer" (click)="goBack()">Revenir à la page précédente</a>
            <br>
            <a style="text-decoration: underline; color:blue;cursor:pointer" (click)="logOut()">Aller à la page d'accueil</a>

          </div>

          
        </div>
      </div>
    </div>
  </div>
</div>
