import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";

@Component({
  selector: 'app-lock-screen-modal',
  templateUrl: './lock-screen-modal.component.html',
  styleUrls: ['./lock-screen-modal.component.scss']
})
export class LockScreenModalComponent implements OnInit {

  itemToSave : any = {};
  busyGet: Subscription;
  busySave: Subscription;
  listTypeAntecedent : Array<any> = [];
  listAntecedentToSave : any= [];
  user : any = {};
  loading : boolean;
  currentData: any;
  currentDossier: any;
  dossierId: any;
  modalRef?: BsModalRef;
  title='Modifier mot de passe'
  listAssurances: any;
  confirmnewPassword:any;
  password: string;
  isLoading=false
  motDepass: string;
  constructor(public bsModalRef: BsModalRef,private authService: AuthService, private restClient: RestClientService,private modalService: BsModalService,private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
    
    
  }

  clearField(event: any) {
    event.target.value = '';
  }
  loginUser() {

    let request={
      data: {
        login: this.user.login,
        password: this.motDepass
      }
  }
  this.isLoading=true
    this.restClient.post('user/login',request).subscribe(
      (res : any) => {
        if (res && !res['hasError']) {
          this.bsModalRef.hide()
          
          this.isLoading=false

        } else {
          
          this.utilities.showNotification("snackbar-danger",
            this.utilities.formatMsgServeur(res['status']['message']),
            "bottom",
            "center");
          // this.error = res['status']['message'];
          this.isLoading=false

        }
      },
      (error) => {
        this.isLoading=false
        this.utilities.showNotification("snackbar-danger",
        error,
          "bottom",
          "center");
      }
    )
  }

  

  

  



  ngOnInit(): void {
    this.motDepass = ''
    
  }


}
