import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-journal',
  templateUrl: './main-journal.component.html',
  styleUrls: ['./main-journal.component.scss']
})
export class MainJournalComponent implements OnInit {

  indiceTab: number=0;

  constructor() { }

  ngOnInit(): void {
  }

  selectedChangeValue(event : any)
  {
    console.log("le event selectedChangeValue",event);
    
    if(!event) this.indiceTab = 0;

    this.indiceTab = event;
  } 

}
