import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';

@Component({
  selector: 'app-tableau-de-bord',
  templateUrl: './tableau-de-bord.component.html',
  styleUrls: ['./tableau-de-bord.component.scss']
})
export class TableauDeBordComponent implements OnInit {

  currentDay=new Date();
  modalRef: BsModalRef;
  isLoading=false;
    user: any = {};
    itemToSearch: any = {};
    busyGet: Subscription
    data = [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]
    highchart: any
    items: any;
    bsRangeValue: Date[];
    minDate = new Date();
    maxDate = new Date();
    currentDate = new Date();
    totalSecteurs = 0
    totalControleurs = 0
    totalHospit: any;
    itemsSecteur: any;
    userImg:any
    colors = ['#236CB7', '#644033', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417'];
  itemDonneeJour: any={};
  dateValeur: Date;

  constructor(private httpClient:HttpClient,private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = 1
    // this.maxDate.setDate(this.currentDate.getDate()+30);
    // this.minDate.setDate(this.currentDate.getDate()-30);
    let now = new Date();
    let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    this.minDate = firstDay
    this.maxDate = lastDay
    this.dateValeur = new Date();
    // this.currentDay=new Date()





    
}


  onChangeDay(){
    this.getDataJour(this.currentDay)
  }

  getDataJour(day?) {
    let request = {
        user: this.user.id,
        // data: moment().format("Y")
        data:{
          // dateJour:"2022-09-07" 
          dateJour:day?moment(day).format("YYYY-MM-DD") :moment().format("YYYY-MM-DD") 

        }

    }

    this.isLoading=true
    
    console.log('res: ', request);

    this.busyGet = this.restClient.post('pharmacieMedicament/kpisJourDashboard', request).subscribe({
        next: (res: any) => {
            console.log('res here', res);
            this.isLoading=false

            if (res && res.item && !res.hasError) {
                this.itemDonneeJour = res.item;
            }
            else {
                this.itemDonneeJour = [];
                if (res.hasError) {
                    this.utilities.showNotification("snackbar-danger",
                        this.utilities.formatMsgServeur(res.status.message),
                        "top",
                        "right");
                    return
                }
            }
            // this.drawBarChart(this.items)


        },
        error: (err) => {

            this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(err),
                "top",
                "right");
        },
        complete: () => console.info('complete')
    })
}

checkIfHasFonct(funct:any){
  let listFuncts = this.authService.currentUserValue.fonctionnalites
  return !!listFuncts.filter(lf=>lf.code ==funct).length
}

  ngOnInit(): void {
    this.getDataJour()
  }

}
