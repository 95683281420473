<section class="section-parametre" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
    <div class="">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="body table-responsive" style="min-height: 400px;">
                        <table class="table table-condensed">
                            <thead>
                                <tr>
                                    <th colspan="12">
                                        <div class="row mt-1">
                                            <div class="col-md-5">
                                                <input [(ngModel)]="itemToSearch.libelle"
                                                    class="form-control custom-input pull-left"
                                                    placeholder="Rechercher ..."
                                                    (keyup)="currentPage = 1; getItems()" />
                                            </div>

                                            <!-- <div class="col-md-7">
                                                <button type="button" class="btn btn-md custom_btn_primary pull-right"
                                                    (click)="openModal(null,templateCrud)">
                                                    <i class="fa fa-plus"></i>&nbsp; Ajouter
                                                </button>
                                            </div> -->
                                        </div>
                                    </th>
                                </tr>
                                <tr class="custom-table-head">
                                    <th>
                                        <a style="cursor: pointer" (click)="currentPage = 1; getItems()"
                                            title="Actualiser">
                                            <i class="fa fa-redo-alt"></i></a>&nbsp;&nbsp; #
                                    </th>
                                    <th>Code</th>
                                    <th>Montant</th>
                                    <th>Date journal</th>
                                    <th>Enregistré le</th>
                                    <th>Enregistré par</th>
                                    <th>Statut</th>
                                    <!-- <th>Montant TTC</th>
                                    <th>Date facture</th> -->
                                    <th class="txt-align-center">Actions</th>
                                </tr>
                            </thead>

                            <tbody *ngFor="let item of items; let indice = index" [class.row-paire]='indice % 2 == 0'>
                                <tr>
                                    <td class="txt-align-center">
                                        <img src="assets/images/images-app/details_open.png" class="img-details"
                                            *ngIf="!item?.isDetailsOpen" (click)="onToggle(item);" />
                                        <img src="assets/images/images-app/details_close.png" class="img-details"
                                            *ngIf="item?.isDetailsOpen" (click)="onToggle(item);" />
                                        {{ currentPage>1?(((currentPage - 1) *
                                        this.itemsPerPage) + (indice+1)):indice+1 }}
                                    </td>
                                    <td>{{item?.code}}</td>
                                    <td>{{item?.amountJournal | mask:'separator'}}</td>
                                    <td>{{item?.dateJournal }}</td>
                                    <td>{{item?.createdAt}}</td>
                                    <td>{{item?.enregistrerPar}}</td>
                                    <td>{{item?.isValidated?'Validé':'En attente'}}
                                    </td>
                                    <td>
                                        <div ngbDropdown class="d-inline-block">
                                            <button type="button" class="btn btn-custom-action-dropdown" id="action"
                                                ngbDropdownToggle>Actions</button>
                                            <div ngbDropdownMenu aria-labelledby="action"
                                                class="dropdown-validation-plainte">
                                                <button ngbDropdownItem *ngIf="!item.isValidated"
                                                    (click)="confirmValidate(item)">
                                                    &nbsp;
                                                    Valider
                                                </button>
                                                <button ngbDropdownItem (click)="onPrintItem(item)">
                                                    &nbsp;
                                                    Imprimer
                                                </button>
                                                <!-- <button ngbDropdownItem (click)="openModal(item,templateCrud)">
                                                    &nbsp;
                                                    Modifier
                                                </button>
                                                <button ngbDropdownItem (click)="confirmDelete(item)">
                                                    &nbsp;
                                                    Supprimer
                                                </button> -->


                                            </div>
                                        </div>

                                    </td>
                                </tr>
                                <tr *ngIf="item?.isDetailsOpen">
                                    <td colspan="12" style="background-color: #ffffff;">
                                        <div class="row" style="background-color: #ffffff;">
                                            <div class="col-md-12 col-xs-12 col-sm-12">
                                                <table class="table table-bordered table-condensed">
                                                    <thead>
                                                        <tr>

                                                            <td colspan="12" style="text-align: center;">
                                                                Règlements</td>

                                                        </tr>
                                                        <tr class="custom-table-head">
                                                            <th style="width: 30px;" class="txt-align-center"> #
                                                            </th>
                                                            <th>Code</th>
                                                            <th>Client</th>
                                                            <th>Date règlement</th>
                                                            <th>Mode règelement</th>
                                                            <th>Motif règelement</th>
                                                            <th>Montant</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngIf="item.datasReglement && item.datasReglement.length">
                                                        <tr
                                                            *ngFor="let item2 of item.datasReglement; let indice2 = index">
                                                            <td class="txt-align-center">
                                                                {{indice2 + 1}}</td>
                                                            <td>{{item2?.numeroReglement }} </td>
                                                            <td>{{item2?.clientNom }}
                                                            </td>
                                                            <td>{{item2?.dateReglement }} </td>
                                                            <td>{{item2?.modeReglementLibelle }} </td>
                                                            <td>{{item2?.motifReglementLibelle }} </td>
                                                            <td>{{item2?.montant | mask:'separator'
                                                                }} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </td>

                                </tr>
                            </tbody>


                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>